<template>
  <div class="row">
    <div class="col-12 col-md-12 mt-4 mb-2">
      <b>Vincular histórico de lançamento a este preço:</b>
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="historicoLancamentoId"
        v-model="form.historicoLancamentoId"
        :label="$t(`GERAL.HISTORICO_LANCAMENTO`)"
        :placeholder="$t(`GERAL.SELECIONE_UMA_OPCAO`)"
        :options="opcoes.historicosLancamentos"
        required
      />
    </div>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import ContaReceberService from '@/common/services/conta-receber/contaReceber.service.js';

// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: { InputSelectSearch },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getHistoricosLancamentos();
  },
  data() {
    return {
      opcoes: {
        historicosLancamentos: [],
      },
    };
  },
  methods: {
    getHistoricosLancamentos() {
      this.$store.dispatch(START_LOADING);
      ContaReceberService.buscarHistoricoContaReceber()
        .then((itens) => {
          itens = itens.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.historicosLancamentos = itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
