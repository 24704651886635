<template>
  <div class="row">
    <div class="col-12 col-md-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :label="$t('FINANCEIRO.DESCRICAO')"
        :placeholder="$t('FINANCEIRO.DESCRICAO')"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="anoLetivoId"
        v-model="form.anoLetivoId"
        :label="$t(`GERAL.ANO`)"
        :placeholder="$t(`GERAL.SELECIONE_UMA_OPCAO`)"
        :options="opcoes.anosLetivos"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-number
        ref="valorBase"
        v-model="form.valorBase"
        :label="$t('FINANCEIRO.VALOR')"
        placeholder="Somente números"
        min="0"
        decimal
        required
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';

// Components:
import { InputText, InputNumber, InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputNumber,
    InputText,
    InputSelectSearch,
  },
  data() {
    return {
      opcoes: {
        anosLetivos: [],
      },
    };
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getAnosLetivos();
  },
  methods: {
    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data.forEach((element) => {
            element.text = element.ano;
            element.value = element.id;
          });
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
