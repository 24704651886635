<template>
  <b-row class="row">
    <div class="col-12 col-md-12 mt-4 mb-2">
      <b>Vincular curso ou grupo turma a este preço:</b>
    </div>

    <b-col md="6">
      <input-select-search
        ref="curso"
        v-model="selecionados.curso"
        :label="$t(`GERAL.CURSOS`)"
        :placeholder="$t(`GERAL.SELECIONE_UMA_OPCAO`)"
        :options="opcoes.cursos"
      />
    </b-col>

    <b-col md="4">
      <input-select-search
        ref="grupoTurma"
        v-model="selecionados.grupoTurma"
        :label="$t(`GERAL.GRUPO_TURMAS`)"
        :options="filtrarGrupoTurmas"
        :isDisabled="desabilitar.grupoTurma"
      />
    </b-col>
    <!-- botão para adicionar na tabela -->
    <b-col md="auto">
      <b-button
        variant="primary"
        class="w-100 botao-acao-filtro mb-3"
        @click="adicionar"
        :disabled="desabilitar.grupoTurma"
      >
        Adicionar
      </b-button>
    </b-col>
    <!-- tabela de vínculos -->
    <b-table
      :fields="tabela.fields"
      :items="itensTabela"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      responsive
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="excluir(item)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-row>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import CursoService from '@/common/services/academico/curso.service';
import GruposTurmaService from '@/common/services/academico/gruposTurma.service.js';

// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
    anoLetivoId: { type: String, default: String },
  },
  components: {
    InputSelectSearch,
  },
  data() {
    return {
      opcoes: {
        cursos: [],
        grupoTurmas: [],
      },
      selecionados: {
        curso: {},
        cursos: [],
        grupoTurma: {},
        grupoTurmas: [],
      },
      itensTabela: [],
      tabela: {
        fields: [
          {
            key: 'curso.descricao',
            label: 'Curso',
            sortable: true,
          },
          {
            key: 'grupoTurma.descricao',
            label: 'Grupo turma',
            sortable: true,
          },
          {
            key: 'acoes',
            label: 'Ações',
          },
        ],
      },
      desabilitar: {
        grupoTurma: true,
      },
    };
  },
  mounted() {
    if (this.form.grupoTurmas) {
      this.selecionados.grupoTurmas = this.form.grupoTurmas;
    }
    if (this.form.cursos) {
      this.selecionados.cursos = this.form.cursos;
    }
    this.getCursos();
    this.montarItensTabela();
  },
  watch: {
    'selecionados.curso.id': {
      handler() {
        this.getGrupoTurma();
      },
      deep: true,
    },
    'selecionados.cursos': {
      handler(newValue) {
        this.$emit('vinculos-curso', newValue);
      },
      deep: true,
    },
  },
  computed: {
    filtrarGrupoTurmas() {
      if (!this.form?.grupoTurmas?.length) return this.opcoes.grupoTurmas

      return this.opcoes.grupoTurmas.filter(opcoes =>
        !this.form.grupoTurmas.some(selected => selected.id === opcoes.id))
    },
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getCursos() {
      this.$store.dispatch(START_LOADING);
      CursoService.listar()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.cursos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getGrupoTurma() {
      let filtro = {
        cursoId: this.selecionados.curso.id,
      };
      this.$store.dispatch(START_LOADING);
      GruposTurmaService.obterTodos(filtro)
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));

          this.opcoes.grupoTurmas = data;
          this.desabilitar.grupoTurma = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES AUXILIARES:
    habilitarCampo(key) {
      this.desabilitar[key] = false;
    },
    montarItensTabela() {
      if (this.form.grupoTurmas) {
        this.form.grupoTurmas.forEach(grupoTurma => {
          const cursoEncontrado = this.form.cursos.find(curso => curso.id === grupoTurma.cursoId);
          this.itensTabela.push({
            curso: cursoEncontrado,
            grupoTurma: grupoTurma,
          });
        });
      }
    },
    // FUNÇÕES VÍNCULO GRUPO TURMAS:
    adicionar() {
      if (helpers.objectIsEmpty(this.selecionados.curso)) return;
      const cursoSelecionado = { ...this.selecionados.curso };

      if (!this.selecionados.grupoTurma.value && this.selecionados.curso.value) {
        this.filtrarGrupoTurmas.forEach(grupoTurma => {
          if (grupoTurma.cursoId === cursoSelecionado.id) {
            this.itensTabela.push({
              curso: cursoSelecionado,
              grupoTurma: grupoTurma
            });
          }
        });
      } else {
        const grupoTurmaSelecionado = { ...this.selecionados.grupoTurma };
        this.itensTabela.push({
          curso: cursoSelecionado,
          grupoTurma: grupoTurmaSelecionado
        });
      }

      this.form.cursos = this.itensTabela.map(item => item.curso);
      this.form.grupoTurmas = this.itensTabela.map(item => item.grupoTurma);


      this.$refs.curso.content = {};
      this.$refs.grupoTurma.content = {};
    },

    excluir(item) {
      const indexTabela = this.itensTabela.findIndex(
        element => element === item.item
      );

      if (indexTabela !== -1) {
        const itemExcluido = this.itensTabela[indexTabela];

        if (itemExcluido.grupoTurma) {
          const grupoTurmaId = itemExcluido.grupoTurma.id;
          const grupoTurmaIndex = this.selecionados.grupoTurmas.findIndex(grupoTurma => grupoTurma.id === grupoTurmaId);
          if (grupoTurmaIndex !== -1) {
            this.selecionados.grupoTurmas.splice(grupoTurmaIndex, 1);
          }
        }

        this.itensTabela.splice(indexTabela, 1);
        this.form.cursos = this.itensTabela.map(item => item.curso);
        this.form.grupoTurmas = this.itensTabela.map(item => item.grupoTurma);
      }
    },
  },
  };
</script>
