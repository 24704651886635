<template>
  <b-row class="mb-3">
    <b-col md="3">
      <input-text
        ref="filtroPreco"
        v-model="filtro.descricao"
        :label="$t('ACADEMICO_MATRICULAS.FILTRO_LABEL')"
        :placeholder="$t('PRECOS.DESCRICAO')"
        @enter="buscar"
      />
    </b-col>
    <b-col cols="3">
      <input-select
        ref="anoLetivoId"
        :options="opcoes.anosLetivos"
        v-model="filtro.anoLetivoId"
        :label="$t('GERAL.ANO_LETIVO')"
        valueField="id"
        textField="ano"
      />
    </b-col>
    <b-col cols="12" md="auto" class="mb-2 mb-md-0">
      <b-button
        variant="primary"
        class="w-100 botao-acao-filtro"
        @click="buscar"
      >
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </b-col>
    <b-col cols="12" md="auto" class="mb-2 mb-md-0">
      <b-button
        variant="secondary"
        class="w-100 botao-acao-filtro"
        @click="limparFiltro"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';

// Components:
import { InputText, InputSelect } from '@/components/inputs/index';

// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

export default {
  components: {
    InputText,
    InputSelect
  },
  data() {
    return {
      filtro: {
        descricao: null,
        filtro: null,
      },
      opcoes: {
        anosLetivos: [],
      },
    };
  },
  mounted() {
    this.getAnosLetivo();
  },
  methods: {
    buscar() {
      this.$emit('filtrar', this.filtro);
    },
    limparFiltro() {
      this.filtro.descricao = null;
      this.filtro.anoLetivoId = null;
      this.$emit('limpar-filtro', (this.filtro = { descricao: '' }));
    },
    getAnosLetivo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
