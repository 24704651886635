<template>
  <b-table
    :items="items"
    :fields="fields"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item.item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Service & Models:
import PrecoService from '@/common/services/precos/preco.service';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'descricao',
          label: this.$t('GERAL.DESCRICAO'),
          sortable: true,
        },
        {
          key: 'anoLetivo',
          label: this.$t('GERAL.ANO_LETIVO'),
          sortable: true,
        },
        {
          key: 'valorBase',
          label: this.$t('GERAL.VALOR_BRL'),
          sortable: true,
          formatter: (value) => {
            return value ? helpers.formatarReal(value) : '-';
          },
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    editar(item) {
      this.buscarPreco(item);
    },
    excluir(item) {
      this.$emit('excluir', item);
    },
    buscarPreco(item) {
      this.$store.dispatch(START_LOADING);
      PrecoService.buscarPorId(item.id)
        .then(({ data }) => {
          this.$emit('editar', 'preco', data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
