<template>
  <div class="row">
    <div class="col-12 col-md-12 mt-4 mb-2">
      <b>Vincular forma de pagamento a este preço:</b>
    </div>
    <div class="col-12 col-md-6">
      <input-select-search
        ref="formasPagamentoId"
        v-model="selecionados.itemFormaPagamento"
        :label="$t(`GERAL.FORMAS_PAGAMENTO`)"
        :placeholder="$t(`GERAL.SELECIONE_UMA_OPCAO`)"
        :options="formasDePagamento"
      />
    </div>

    <div class="col-12 mb-3 col-md-auto">
      <b-button
        class="w-100 botao-acao-filtro mb-3"
        variant="primary"
        @click="adicionar"
      >
        Adicionar forma de pagamento
      </b-button>
    </div>

    <!-- tabela de vínculos -->
    <b-table
      :fields="tabela.fields"
      :items="form.formasPagamento"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      responsive
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="excluir(item)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Service
import FormaPagamentoService from '@/common/services/forma-pagamento/forma-pagamento.service';

// Components:
import { InputSelectSearch } from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: () => ({}) },
  },
  components: { InputSelectSearch },
  mounted() {
    this.getFormasPagamento()
  },
  data() {
    return {
      opcoes: {
        formasPagamento: [],
      },
      selecionados: {
        itemFormaPagamento: {},
      },
      tabela: {
        fields: [
          {
            key: 'descricao',
            label: 'Descrição',
            sortable: true,
          },
          {
            key: 'acoes',
            label: 'Ações',
          },
        ],
      },
    };
  },
  computed: {
    formasDePagamento() {
      if (!this.form?.formasPagamento?.length) return this.opcoes.formasPagamento 
      
      return this.opcoes.formasPagamento.filter(opcoes=> 
        !this.form.formasPagamento.some(selected => selected.id === opcoes.id));
    },
  },
  methods: {
    // FUNÇÕES DE OBTENÇÃO DE DADOS:
    getFormasPagamento() {
      this.$store.dispatch(START_LOADING);
      FormaPagamentoService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.formasPagamento = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES VÍNCULO GRUPO TURMAS:
    adicionar() {
      if (!this.form.formasPagamento) {
        this.$set(this.form, 'formasPagamento', []);
      }
      if (helpers.objectIsEmpty(this.selecionados.itemFormaPagamento)) return;

      const item = this.opcoes.formasPagamento.findIndex(
        (element) => element.id === this.selecionados.itemFormaPagamento.id
      );
      if (item !== -1) {       
        this.form.formasPagamento.push(this.selecionados.itemFormaPagamento);
        this.form.formasPagamentoId = this.form.formasPagamento.map(i => i.id);
      }
      this.selecionados.itemFormaPagamento = {};
    },
    excluir(item) {
      this.form.formasPagamento = this.form.formasPagamento.filter(i => i.id != item.item.id)
      this.form.formasPagamentoId = this.form.formasPagamento.map(fp => fp.id)
      this.selecionados.itemFormaPagamento = {};
    },
  },
};
</script>
<style>
.padding-top {
  padding-top: 10px;
}
</style>
