<template>
  <div class="container">
    <!-- dados de estrutura -->
    <form-dados-estruturais :form="form" @refs="handleRefs" />

    <hr />
    <!-- Vínculo: histórico lançamento -->
    <form-vinculos-historico-lancamento :form="form" @refs="handleRefs" />
    <hr />

    <!-- Vínculo: forma de pagamento -->
    <form-vinculos-forma-pagamento :form="form" />

    <hr />
    <!-- Abrir vínculos para: cursos, grupo turmas e turmas -->
    <b-row>
            <!-- Vínculo: grupo turmas -->
            <form-vinculos-grupo-turma
              :form="form"
              :cursoId="selecionados.cursoId"
              :anoLetivoId="selecionados.anoLetivoId"
              :desabilitar="desabilitar.turma"
            />
    </b-row>
  </div>
</template>
<script>
// Components:
import FormDadosEstruturais from './DadosEstruturais';
import FormVinculosHistoricoLancamento from './VinculosHistoricoLancamento';
import FormVinculosFormaPagamento from './VinculosFormaPagamento';
import FormVinculosGrupoTurma from './VinculosGrupoTurma';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    FormDadosEstruturais,
    FormVinculosHistoricoLancamento,
    FormVinculosFormaPagamento,
    FormVinculosGrupoTurma,
  },
  data() {
    return {
      refs: [],
      exibir: {
        cursos: false,
        grupoTurmas: false,
        turmas: false,
      },
      selecionados: {
        cursoId: '',
        grupoTurma: {},
        grupoTurmas: [],
      },
      desabilitar: {
        turma: true,
        grupoTurma: true,
      },
    };
  },
  watch: {
    selecionados: {
      handler(selecionado) {
        if (selecionado.cursos) {
          this.getTurmas();
        }
        if (selecionado.cursos && this.form.anoLetivoId) {
          this.habilitarCampo('grupoTurma');
          this.getGrupoTurma();
        }
      },
      deep: true,
    },
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    handleRefs(newRefs) {
      this.refs = { ...this.refs, ...newRefs };
      this.$emit('refs', this.refs);
    },
    // FUNÇÕES AUXILIARES:
    habilitarCampo(key) {
      this.desabilitar[key] = false;
    },
    vincular(key) {
      for (const item in this.exibir) {
        this.exibir[item] = false;
      }
      this.exibir[key] = true;
    },
  },
};
</script>
