<template>
  <div>
    <titulo-precos @cadastrar="openModal" />

    <filtro-preco
      @filtrar="getFiltro"
      @limpar-filtro="getPrecosPorFiltroPaginado"
    />

    <Tabela :items="precos" @editar="openModal" @excluir="confirmarExclusao" />

    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getPrecosPorFiltroPaginado"
    />

    <modal-preco
      :form="modais.precoDados"
      :exibir="modais.preco"
      @salvar="salvar"
      @fechar="closeModal('preco')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Service & Models:
import PrecoService from '@/common/services/precos/preco.service';

// Components:
import Tabela from './components/Tabela';
import ModalPreco from './components/Modal';
import Paginacao from '@/components/paginacao/Paginacao';
import FiltroPreco from '@/views/financeiro/estrutura/precos/components/Filtro';
import TituloPrecos from '@/views/financeiro/estrutura/precos/components/TituloPrecos.vue';

export default {
  components: {
    TituloPrecos,
    Tabela,
    ModalPreco,
    Paginacao,
    FiltroPreco,
  },
  data() {
    return {
      precos: [],
      expressao: {},
      modais: {
        preco: false,
        precoDados: {},
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
    };
  },
  mounted() {
    this.getPrecosPorFiltroPaginado();
  },
  methods: {
    getFiltro(buscarPor) {
      this.expressao = buscarPor;
      this.getPrecosPorFiltroPaginado();
    },
    getPrecosPorFiltroPaginado(expressao) {
      this.expressao = expressao === '' ? expressao : this.expressao;
      this.$store.dispatch(START_LOADING);
      PrecoService.obterPrecosPorFiltroPaginado(this.expressao, this.paginacao)
        .then(({ data }) => {
          this.precos = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES CRUD:
    salvar(item) {
      if (!item.formasPagamentoId || item.formasPagamentoId?.length == 0 ) {
        return mensagem.warning(
          this.$t('GERAL.AVISO'),
          this.$t('FINANCEIRO.FORMAS_PAGAMENTO_OBRIGATORIO')
        );
      }
      item.id ? this.editar(item) : this.criar(item);
    },
    criar(item) {
      this.$store.dispatch(START_LOADING);
      PrecoService.criar(item)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PRECO_CRIADO')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('preco');
          this.getPrecosPorFiltroPaginado();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    editar(item) {
      this.$store.dispatch(START_LOADING);
      PrecoService.editar(item)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PRECO_EDITADO')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.closeModal('preco');
          this.getPrecosPorFiltroPaginado();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('FINANCEIRO.PRECO_CONFIRMAR_EXCLUSAO')
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      PrecoService.excluir(item.id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PRECO_EXCLUIDO')
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.getPrecosPorFiltroPaginado();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL
    openModal(key, item) {
      this.modais[key] = true;
      this.modais[`${key}Dados`] = item ? JSON.parse(JSON.stringify(item)) : {};
    },
    closeModal(key) {
      this.modais[key] = false;
    },
  },
};
</script>
