<template>
  <modal
    id="modalBancos"
    tamanho="lg"
    titulo="FINANCEIRO.PRECOS"
    :exibir="exibir"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <form-precos :form="form" @refs="getRefs" />
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>

<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

// Components:
import Modal from '@/components/modal/Modal';
import FormPrecos from './form/Index';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object },
  },
  components: {
    Modal,
    FormPrecos,
  },
  data() {
    return {
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    salvar() {
      if (!this.validarFormulario()) return;
      // Tratando formas pagamento Id para enviar ao back-end:
      if (this.form.formasPagamento) {
        this.form.formasPagamentoId = this.form.formasPagamento.map(fp => fp.id);
      }

      // Tratando cursos Id para enviar ao back-end:
      if (this.form.cursos) {
        this.form.cursosId = this.form.cursos.map(curso => curso.id);
      }

      // Tratando grupo turmas Id para enviar ao back-end:
      if (this.form.grupoTurmas) {
        this.form.grupoTurmasId = this.form.grupoTurmas.map(gt => gt.id);
      }
    
      this.$emit('salvar', this.form);
    },
  },
};
</script>
