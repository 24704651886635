import ApiService from "@/common/api/api.service";

let apiBasePath = 'FormaPagamento'

const FormaPagamentoService = {
    // FUNÇÕES CRUD:
    async criar (form) {
        let { data } = await ApiService.post(`${apiBasePath}`, form)
        return data
    },
    async editar (form) {
        let { data } = await ApiService.put(`${apiBasePath}`, form)
        return data
    },
    async excluir (id) {
        return await ApiService.delete(`${apiBasePath}/${id}`)
    },

    // FUNÇÕES OBTENÇÃO DE DADOS:
    async obterTodos () {
        let { data } = await ApiService.get(`${apiBasePath}`)
        return data
    },
    async buscarPorId (id) {
        let { data } = await ApiService.get(`${apiBasePath}/${id}`)
        return data
    }
}

export default FormaPagamentoService