<template>
  <topbar
    :titulo="$t('FINANCEIRO.PRECOS')"
    :subtitulo="$t('FINANCEIRO.PRECOS_DESCRICAO')"
    :breadcrumbs="breadcrumbs"
  >
    <template #header>
      <div class="d-flex justify-content-between ml-md-4 gap-1">
        <div></div>
        <b-button variant="info" @click="cadastrar">
          {{ $t('GERAL.CADASTRAR') }}
        </b-button>
      </div>
    </template>
  </topbar>
</template>
<script>
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

export default {
  components: { Topbar },
  data() {
    return {
      breadcrumbs: [
        { titulo: 'FINANCEIRO.FINANCEIRO' },
        { titulo: 'FINANCEIRO.PRECO' },
      ],
    };
  },
  methods: {
    cadastrar() {
      this.$emit('cadastrar', 'preco');
    },
  },
};
</script>
